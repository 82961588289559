
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '@/services/authentication.service';
  import {TeacherDto} from '@/interfaces/teacherDtos/TeacherDto';
  import * as UrlConsts from '@/scripts/UrlConsts';


  /**
   * @desc composant permettant la selection des enseignants
   */
  @Component({
    components: {},
  })
  export default class TeacherSelector extends Vue {
      teachers: Array<TeacherDto> = [];
      selectedTeachers = [];

      mounted() : void {
        setTimeout(() => {
          this.loadData();
        }, 200);
      }

      /**
       * @desc charge tous les enseignants
       */
      loadData() : void {
        const headers = AuthenticationService.getRequestHeader();

        axios
          .get(  UrlConsts.getAllTeachers, headers)
          .then((response) => {
            this.teachers = response.data.map((x : TeacherDto) => {x.fullName = x.firstname + " " + x.name; return x});

          })
          .catch(error => console.log(error))
      }

      /**
       * @desc défini les enseignants selectionés
       */
      set(value : any) : void {
        this.selectedTeachers = value;
        (this.$refs.selector as any).selectedItems = value;
      }

      /**
       * @desc renvoie les id des enseignants selectionnés
       */
      getData() : Array<number> {
        return (this.$refs.selector as any).selectedItems.map((x : any) => {return x.id});
      }
  }

