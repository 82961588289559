
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import {Rubric} from '../../interfaces/Rubric.interface';
  import {ImageTransferService} from '../../services/image-transfer.service';
  import EditClassroomDialog from '@/components/EditClassroomDialog.component.vue';
  import { Classroom } from '@/interfaces/Classroom.entity';
  import { ClassroomEditionDto } from '@/interfaces/ClassroomEdition.dto';
  import DialogBoxValidation from '@/components/DialogBoxValidation.vue';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';


  const editClassroomHeader = 'Éditer la classe';
  const editClassroomType = 'edit';
  const createClassroomHeader = 'Créer une classe';
  const createClassroomType = 'create';

  /**
   * @desc page de gestion des classes
   */
  @Component({
    components: {
      'edit-classroom-dialog' : EditClassroomDialog,
      'deletion-dialog-box' : DialogBoxValidation
    },
  })
  export default class ClassroomsAdmin extends Vue 
  {
    imageTransferService : ImageTransferService = new ImageTransferService();

    private tableHeaders = [
      { text: '#', sortable: false },
      { text: 'Nom', value: 'name', sortable: true },
      { text: 'Date de début', value: 'startDate', sortable: true },
      { text: 'Date de fin', value: 'endDate', sortable: true},
      { text: 'Date d\'archivation', value: 'archivedDate', sortable: true },
      { text: 'Actions', sortable: false },
      { text: '', sortable: false },
    ];

    private tableItems: Classroom[] = [];

    private allClassrooms : Classroom[] = [];
    selectedClassroom : Classroom = new Classroom();
    classroomEdition = false;

    editClassroomHeader = "Éditer la classe";
    editClassroomType = 'edit';

    private loading= false;
    private tableSortBy = 'startDate';
    private tableDesc = true;

    private dataToggle = 0;

    deletionEventName = 'classDeleted';

    /**
     * @desc initialisation et écoute des événements émis
     */
    mounted() :void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_teacher
        && AuthenticationService.getUserStatus() !== Consts.user_status_admin){
        this.$router.push('/');
        return;
      }

      this.loadClassrooms();
    }

    /**
     * @desc met à jour les classes affichées, 
     * appelé quand les classes archivées / actuelles sont selectionnées
     */
    private dataToggleChanged() : void {
      this.updateShowedClassrooms();
    }

    /**
     * @desc met à jour les classes affichées, si elle sont achivatées ou non
     */
    private updateShowedClassrooms() : void {
      const now = new Date();

      if(this.dataToggle === 1) { // archivés
        this.tableItems = this.allClassrooms.filter(x => x.archivedDate < now);
      }
      else {
        this.tableItems = this.allClassrooms.filter(x => x.archivedDate >= now);
      }
    }

    /**
     * @desc renvoie la Date au format string jj/mm/aaaa
     */
    private toInputDate(date: Date) : string {
      let month = (date.getMonth() +1).toString();
      if(month.length === 1) month = "0" + month;
      let day = date.getDate().toString();
      if(day.length === 1) day = "0" + day;

      return day + '/' + month + '/' +  date.getFullYear().toString();
    }

    /**
     * @desc charge les classes contenues en base de données
     */
    private loadClassrooms() : void { 
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(  UrlConsts.getAllClassrooms, headers)
        .then((response) => {
          this.allClassrooms = [];
          // conversion en instance de Rubric
          for(let i=0; i < response.data.length; ++i) {
            this.allClassrooms.push(new Classroom(response.data[i]));
          }
          this.updateShowedClassrooms();
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc appelle la boite de dialogue de création d'une classe
     */
    private addClassroom() : void {
      this.editClassroomHeader = createClassroomHeader;
      (this.$refs.editClassroomDialog as EditClassroomDialog).show(new Classroom(), createClassroomType);
    }

    serverDialog = false;

    /**
     * @desc requète de création d'une classe envoyée au serveur
     */
    private async serverCreateClassroom(classroom : Classroom) : Promise<void> {
      await new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve(0);
        }, 100);
      });
      if(this.serverDialog) return;
      this.serverDialog = true;
      
      classroom = await this.saveClassroomImg(classroom) as Classroom;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.createClassroom, classroom, headers)
        .then((response) => {
          this.serverDialog = false;
          (this.$refs.editClassroomDialog as EditClassroomDialog).hide();
          const createdRubric = new Classroom(response.data.classroom);
          this.allClassrooms.splice(0, 0, createdRubric);
          this.updateShowedClassrooms();
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc met en forme et sauvegarde une image sur le serveur
     * @returns renvoie la classe modifiée avec le nouvel id d'image
     */
    private async saveClassroomImg(classroom : Classroom | ClassroomEditionDto) : Promise<Classroom | ClassroomEditionDto> {
      if(classroom.imgChanged) {
        if(classroom.imgUrl) {
          const imgId = await this.serverSaveImg(classroom.imgUrl);
          if(imgId) {
            classroom.imgId = imgId;
          }
        }
        else {
          classroom.imgId = -1;
        }
      }
      classroom.imgUrl = "";
      return classroom;
    }

    /**
     * @desc demande la sauvegarde d'une image côté serveur
     * @returns l'id de l'image sauvegardée
     */
    private async serverSaveImg(imgUrl : string) : Promise<number> {
      const result = await this.imageTransferService.transferImg(imgUrl, UrlConsts.saveImg);
      if(result.createdImgId) return result.createdImgId;
      return 0;
    }

    /**
     * @desc ouvre la boite de dialogue d'édition d'une classe
     */
    private editClassroom(classroom : Classroom) : void {
      if(!classroom) return;
      this.selectedClassroom = classroom;
      this.classroomEdition = true;
      this.editClassroomHeader = editClassroomHeader;
      (this.$refs.editClassroomDialog as EditClassroomDialog).show(classroom, editClassroomType);
    }

    /**
     * @desc requète de modification d'une classe envoyée au serveur
     */
    private async serverEditClassroom(classroom : ClassroomEditionDto) : Promise<void> {
      if(this.serverDialog) return;
      this.serverDialog = true;

      classroom = await this.saveClassroomImg(classroom);
      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.editClassroom, classroom, headers)
        .then((response) => {
          this.serverDialog = false;
          (this.$refs.editClassroomDialog as EditClassroomDialog).hide();
          const createdRubric = new Classroom(response.data.classroom);
          this.updateClassroom(createdRubric);
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc met à jour l'affichage d'une classe dans la table
     */
    private updateClassroom(data : Classroom) : void {
      const rubricIndex = this.allClassrooms.findIndex(x => x.id === data.id);
        if(rubricIndex > -1) {
          this.allClassrooms[rubricIndex] = data;
          this.updateShowedClassrooms();
        }
    }
    
    /**
     * @desc ouvre la boite de dialogue de suppréssion d'une classe
     */
    private deleteClassroom(classroom : Classroom) : void {
      if(!classroom) return;
      this.selectedClassroom = classroom;
      this.classroomEdition = true;
      (this.$refs.deleteClassroomDialog as DialogBoxValidation).show(this.selectedClassroom);
    }

    /**
     * @desc envoie une demande de suppression de la classe selectionnée au serveur
     */
    private serverDeleteClassroom(classroom : Classroom) : void {
      if(this.serverDialog) return;
      
      this.serverDialog = true;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .delete(UrlConsts.deleteClassroom + "/" + classroom.id, headers)
        .then(() => {
          this.serverDialog = false;
          const rubricIndex = this.allClassrooms.findIndex(x => x.id === classroom.id);
          if(rubricIndex > -1) {
            this.allClassrooms.splice(rubricIndex, 1);
          }
          this.updateShowedClassrooms();
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc affiche une erreur serveur
     */
    private serverError(error : string) : void{
      console.log(error);
      this.serverDialog = false;
    }
  }
