import { RubricEditionDto } from "./RubricEdition.dto";

/**
 * @desc format de données envoyées pour modifier une classe
 */
export class ClassroomEditionDto extends RubricEditionDto {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {
    super(data);

    if(data && data.teachers)
      this.teachers = data.teachers;
    if(data && data.login)
      this.login = data.login;
    if(data && data.password)
      this.password = data.password;
  }

  teachers?: Array<any>;
  login? : string;
  password? : string;
}