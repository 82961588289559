
  import { Component, Vue } from 'vue-property-decorator';
  import { LoginValidationDto } from '../interfaces/LoginValidationDto';
  import { Classroom } from '../interfaces/Classroom.entity';
  import { AuthenticationService } from '../services/authentication.service';
  import RubricDatesLine from './RubricDatesLine.component.vue';
  import RubricImageSelector from './RubricImageSelector.vue';
  import TeacherSelector from './TeacherSelector.component.vue';

  // types utilisés pour définir le comportement du composant
  const editType = "edit";
  const createType = 'create';

  /**
   * @desc boite de dialogue permettant à l'utilisateur de créer/modifier une classe
   */
  @Component({
    components: {
      'rubric-image-selector' : RubricImageSelector,
      'rubric-dates-line' : RubricDatesLine,
      'teacher-selector' : TeacherSelector
    },
  })
  export default class EditClassroomDialog extends Vue {
    boxWidth = "60rem";

    visible = false;
    classroom: Classroom = new Classroom();
    outputClassroom : Classroom = new Classroom();
    type = 'create'; // default value -> create

    /**
     * @desc message affiché lors de la validation d'un espace de texte
     */ 
    notEmptyMsg = "Ne doit pas rester vide !";


    /**
     * @desc montre la boite de dialogue de edition/creation d'une rubrique
     * @param classroom : object classe initial
     * @param type : défini le comportement du composant, soit 'edit' soit 'create'
     */
    public show(classroom : Classroom, type : string) : void {
      this.classroom = classroom;
      this.outputClassroom = classroom.clone();
      this.type = type;
      this.visible= true;
      this.$vuetify.theme.dark = false;

      setTimeout(() => {
        (this.$refs.rubricImgSelector as RubricImageSelector).loadImg(classroom.imgId);

        const dates =
          {
            startDate: this.classroom.startDate, 
            endDate: this.classroom.endDate, 
            archivedDate: this.classroom.archivedDate
          };
        (this.$refs.rubricDatesLine as RubricDatesLine).init(dates, type === createType);

        (this.$refs.teacherSelector as TeacherSelector).set(this.classroom.teachers);
      }, 200);
    }

    /**
     * @desc cache la boite de dialogue
     */
    public hide() : void {
      this.visible = false;
    }

    /**
     * @desc vérifie si le paramètre input est vide ou non, règle de zone de texte
     * @param errorMsg message d'erreur à renvoyer en cas d'erreur
     */
    private notEmptyRule(input : string, errorMsg : string) : any {
      if(!input || !input.length) return errorMsg;
      return true;
    }

    /**
     * @desc règle appliquée à l'entrée du mot de passe dans la zone de texte associée
     * @param errorMsg message d'erreur à renvoyer en cas d'erreur
     */
    private passwordRule(input : string, errorMsg : string) {
      if(this.type === editType) return true;
      return this.notEmptyRule(input, errorMsg);
    }


    /**
     * @desc fonction qui valide les données rentrées, et les renvoie en emettant un événement associé
     */
    public validate() : void{
      if(!this.checkInputData()) return;

      if(!this.visible) return;
      let editedClassroom = this.getUpdatedData();
      if(this.type === editType) {
        const classroomEditionDto = editedClassroom.createClassroomEditionDto(this.classroom);
        if(Object.keys(classroomEditionDto).length <= 1){
          this.cancel();
        }
        else this.$emit('classValidated', classroomEditionDto)
      }
      else{
        this.$emit('classCreated', editedClassroom)
      }
    }

    /**
     * @desc vérifie si les données rentrées sont conformes
     * @returns false en cas de problème
     */
    private checkInputData() : boolean {
      if(!this.outputClassroom.name) return false;
      if(!(this.$refs.authInputForm as any).validate()) return false;
      if((this.$refs.rubricDatesLine as RubricDatesLine).hasError()) return false;

      return true;
    }

    /**
     * @desc renvoie un object contenant toutes les données renseignées par l'utilisateur
     */
    private getUpdatedData() : Classroom {
      const dates = (this.$refs.rubricDatesLine as RubricDatesLine).getDates();
      this.outputClassroom.startDate = dates.startDate;
      this.outputClassroom.endDate = dates.endDate;
      this.outputClassroom.archivedDate = dates.archivedDate;

      this.outputClassroom.teachers = (this.$refs.teacherSelector as TeacherSelector).getData();

      const rubricImgSelector = this.$refs.rubricImgSelector as RubricImageSelector;
      if(rubricImgSelector.hasChanged()){
        this.outputClassroom.imgUrl = rubricImgSelector.getDataUrl();
        this.outputClassroom.imgChanged = true;
      }

      return this.outputClassroom;
    }

    /**
     * @desc annule les modifications apportées. Cache simplement la boite de dialogue
     */
    public cancel() : void {
      setTimeout(() => {
        this.visible = false;
      }, 200);
    }
  }

