import { ClassroomEditionDto } from "./ClassroomEdition.dto";
import { Rubric } from "./Rubric.interface";
import { RubricEditionDto } from "./RubricEdition.dto";

const classroomType = 2;

/**
 * @desc format de données envoyées pour créer une classe / reçues pour afficher une classe
 */
export class Classroom extends Rubric {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {
    super(data);
    this.type = classroomType;
    this.teachers = data && data.teachers || [];
    this.login = data && data.login || "";
    this.password = data && data.password || "";
  }

  teachers: Array<any>;
  login : string;
  password : string;
  
  public clone(): any {
    const cloneObj = new Classroom() as any;
    for (const attribut in this) {
      cloneObj[attribut] = this[attribut];
    }
    return cloneObj;
  }

  public createClassroomEditionDto(baseClassroom : Classroom) : ClassroomEditionDto{
    const data = super.createRubricEditionDto(baseClassroom) as any;
    if(!data.id) return new ClassroomEditionDto();

    if(baseClassroom.teachers !== this.teachers)
      data['teachers'] = this.teachers;
    if(baseClassroom.login !== this.login)
      data['login'] = this.login;
    if(this.password)
      data['password'] = this.password;

    return new ClassroomEditionDto(data);
  }
}